<template>
  <div>
    <el-dialog
      :visible.sync="inputVisible"
      width="620px"
      title="批量输入"
      :before-close="handleClose"
      :close-on-click-modal="false"
      :element-loading-background="loadingBackground"
      custom-class="edit-customer-dialog"
      append-to-body
    >
      <div class="el-form">
        <!-- codeValue -->
        <div class="input-content">
          <el-input
            ref="searchInput"
            type="textarea"
            v-model="courierNumber"
            :autosize="{ minRows: 4, maxRows: 30 }"
            placeholder="请输入"
            @keydown.enter.native="courierNumberEnterDown()"
            @keyup.enter.native="courierNumberEnterUp()"
            clearable
          ></el-input>
          <!--  @keyup.native="concludeData" -->
          <div class="tips">
            <i class="el-icon-warning-outline"></i>
            多个请用
            <span class="redcolor">逗号</span>
            <span class="redcolor">分号</span>
            <span class="redcolor">空格</span>
            <span class="redcolor">回车</span>
            分隔
          </div>
        </div>
      </div>
      <div class="dialog-footer" slot="footer">
        <el-button type="primary" @click="clearValue">清空</el-button>
        <el-button type="primary" @click="handleSubmit">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import base from "@/mixins/base";
import listPage from "@/mixins/list-page";
import listPageReszie from "@/mixins/list-page-resize";
export default {
  name: "",
  components: {},
  data() {
    return {
      courierNumber: "",
      keyDownTime: "",
      keyUpTime: ""
    };
  },
  props: {
    inputVisible: {
      type: Boolean,
      default: false
    },
    maxLength: {
      type: Number,
      default: 1000
    },
    //默认值用于回显
    defaultValue: {
      type: Array,
      default: () => []
    }
  },
  mixins: [base, listPage, listPageReszie],
  computed: {},
  watch: {
    inputVisible: {
      immediate: true,
      handler(val) {
        if (val) {
          // 自动获取input输入框焦点
          this.$nextTick(() => {
            this.$refs.searchInput.focus();
            if (this.defaultValue.length) {
              this.courierNumber = this.defaultValue.join("\n");
            }
          });
        }
      }
    }
  },
  methods: {
    courierNumberEnterDown() {
      this.keyDownTime = new Date().getTime();
    },
    courierNumberEnterUp() {
      this.keyUpTime = new Date().getTime();
      if (this.keyUpTime - this.keyDownTime < 30) {
        //扫码枪触发
        console.log("扫码Enter");
      } else {
        //直接按键盘触发
        console.log("直接Enter");
      }
    },
    //清空数据
    clearValue() {
      this.courierNumber = "";
      this.keyDownTime = "";
      this.keyUpTime = "";
    },
    // 关闭弹窗
    handleClose() {
      this.courierNumber = "";
      this.keyDownTime = "";
      this.keyUpTime = "";
      this.$emit("update:inputVisible", false);
    },
    //判断输入是否有重复元素
    getisRepeat(arr) {
      var hash = {};
      for (var i in arr) {
        if (hash[arr[i]]) {
          return true;
        }
        hash[arr[i]] = true;
      }
      return false;
    },
    // 提交弹窗的输入结果
    handleSubmit() {
      let list = this.courierNumber
        .split(/\n|,|，|；|;| /g)
        .filter(item => item);
      if (this.getisRepeat(list)) {
        this.$message.warning("有重复编码,请修改");
        return;
      }
      if (list.length > this.maxLength) {
        this.$message.warning(`已超出最大输入个数${this.maxLength}的限制`);
        return;
      }
      //以回车，空格，英文逗号为分隔符，分割字符串，分割结果得到一个数组
      // let temp = this.courierNumber.split(/\n|,|，|；|;| /g);
      this.$emit("confirm", list);
      this.handleClose();
    }
  },
  created() {}
};
</script>
<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";
.redcolor {
  color: #f00;
}
/deep/.edit-customer-dialog {
  .el-form {
    padding: 20px 20px 0;
    overflow: hidden;
  }
  .label {
    width: 100px;
    float: left;
    text-align: right;
    padding: 0 10px;
    box-sizing: border-box;
  }
  .input-content {
    width: calc(100% - 100px);
    margin-bottom: 20px;
    float: left;
  }
}
</style>
